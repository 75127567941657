import React from 'react';
import '../styles/Process.css';
import amazon from '../assets/images/amazon-img.png';
import ebay from '../assets/images/ebay-img.png';
import costco from '../assets/images/costco-img.png';
import walmart from '../assets/images/walmart-img.png';
import aliexpress from '../assets/images/aliexpress-img.png';
import jumia from '../assets/images/jumia-img.png';
import hp from '../assets/images/hp-img.png';
import apple from '../assets/images/apple-img.png';
import alibaba from '../assets/images/alibaba-img.png';
import samsung from '../assets/images/samsung-img.png';
import dell from '../assets/images/dell-img.png';
import { Fade } from 'react-awesome-reveal';
import Logo from '../components/Logo';
import Navbar from '../components/Navbar';



const MoreinfooOfProcess = () => {

    const handlenav = () => {
        window.location.href = '/login';
    }


    const checkLoggedIn = () => {
        const token = localStorage.getItem('token');
        return token !== null;
      };
      
      const isLoggedIn = checkLoggedIn();
    

    const images = [amazon, ebay, costco, walmart, aliexpress, jumia, hp, apple, alibaba, samsung, dell];
    const imagelinks = ['https://www.amazon.com/', 'https://www.ebay.com/', 'https://www.costco.com/', 'https://www.walmart.com/', 'https://www.aliexpress.com/', 'https://www.jumia.com/', 'https://www.hp.com/', 'https://www.apple.com/', 'https://www.alibaba.com/', 'https://www.samsung.com/', 'https://www.dell.com/'];

    return (
        <div className="process-container">
            <Logo />
            <div className="home-section">
                <div className="process-left-column">
                <Fade direction='top' duration={2000}>
                    <h1>Welcome to P2P Logistics</h1>
                </Fade>
                </div>
            </div>

            <Fade direction='bottom' duration={2000}>
            <div className="more-info">
            <h1>5 simple Steps</h1>
            <div className="info-container">

                <div className="info-box">

                    <div className="process-box">
                    <img width="50" height="50" src="https://img.icons8.com/plasticine/100/address.png" loading='lazy' alt="address-img"/>
                    <h3>Generate a P2P address</h3>
                    </div>

                    <div className='process-description'>
                        <p>First register and login to your account, a Uk address would be generated for you upon logging in</p>
                    </div>
                
                </div>

                <div className="info-box">

                    <div className="process-box">
                        <img width="50" height="50" src="https://img.icons8.com/clouds/100/overtime.png" loading='lazy' alt="overtime-img"/>
                        <h3>Copy the Address</h3>
                    </div>

                    <div className='process-description'>
                        <p style={{color:"black"}}>Copy the address generated upon login and use it as your shipping address when purchasing your items</p>
                    </div>


                </div>


                <div className="info-box">

                <div className="process-box">
                    <img loading='lazy' width="50" height="50" src="https://img.icons8.com/external-flat-wichaiwi/64/external-diligent-small-business-flat-wichaiwi.png" alt="purchase-step-img"/>
                    <h3>Purchase your item</h3>
                </div>
                    <div className='process-description'>
                        <p style={{color:"black"}}>Go to your preferred online store and send your purchased items to the Uk address provided to you.</p>
                   </div>
                   
                </div>


                <div className="info-box">
                <div className="process-box">
                    <img loading='lazy' width="50" height="50" src="https://img.icons8.com/external-goofy-color-kerismaker/96/external-Processing-office-goofy-color-kerismaker.png" alt="confirm-step-img"/>
                    <h3>Confirmation & Processing</h3>
                </div>
                
                    <div className='process-description'>
                        <p>Once we receive your shipment we would send you a confirmation email with your tracking information and process your item</p>
                    </div>
                
                </div>

                <div className="info-box">

                <div className="process-box">
                    <img loading='lazy' width="50" height="50" src="https://img.icons8.com/external-parzival-1997-flat-parzival-1997/64/external-pickup-the-new-normal-touchless-parzival-1997-flat-parzival-1997.png" alt="track-step-img"/>
                    <h3>Track your item</h3>
                </div>
                
                    <div className='process-description'>
                        <p>Once your shipment is in transit you can track your shipment using the tracking number in the information provided in the email</p>
                    </div>
                </div>
            </div>

            {!isLoggedIn && (
            <button onClick={handlenav}>Get Started</button>
            )}

        </div>

            </Fade>


            

            <div className="suggested-stores">
                <div className='suggested-stroes-text'>
                <h1>Suggested Stores to Shop From</h1>
                <p style={{color: "black"}}>You can choose to start with any of the suggested shopping links below.</p>
                </div>

                <div className="store-logos">
                {images.map((image, index) => (
                    <div key={index} className='box'>
                        <a key={index} href={imagelinks[index]}>
                            <img loading='lazy' width="48" height="48" src={image} alt="store-imgs"/>
                        </a>
                    </div>
                    ))}
                </div>
            </div>
            <Navbar />

        </div>
    );
};

export default MoreinfooOfProcess;
