// NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Error.css';

const Error = () => {
  return (
    <div className="not-found">
      <h1 className="large-404">404</h1>
      <p>Oops! The page you are looking for does not exist.</p>
      
      <img 
        src="https://via.placeholder.com/150" 
        alt="Package Icon" 
        className="package-image" 
      />
      
        <button onClick={() => window.location.href = '/'} className="back-home-btn">Go Back Home</button>

    </div>
  );
};

export default Error;
