import React, { useState, useEffect, useRef } from 'react';
import socketIOClient from 'socket.io-client';
import axios from 'axios';
import './AdminChat.css';
import Logo from '../../components/Logo';

const ENDPOINT = 'http://localhost:5000';

const AdminChat = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const socketRef = useRef();

  useEffect(() => {
    socketRef.current = socketIOClient(ENDPOINT);
    socketRef.current.emit('setUsername', 'p2p_superadmin');

    socketRef.current.on('message', (message) => {
      if (message.sender === selectedUser || message.recipient === 'p2p_superadmin') {
        const formattedMessage = {
          ...message,
          timestamp: formatTimestamp(message.timestamp)
        };
        setMessages((prevMessages) => [...prevMessages, formattedMessage]);
      }
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, [selectedUser]);

  useEffect(() => {
    const fetchUsers = async () => {
      const response = await axios.get(`${ENDPOINT}/messages`);
      const uniqueUsers = [...new Set(response.data.map(msg => msg.sender))];
      setUsers(uniqueUsers);
    };

    fetchUsers();
  }, []);

  const selectUser = async (username) => {
    setSelectedUser(username);
    const response = await axios.get(`${ENDPOINT}/messages/${username}`);
    const formattedMessages = response.data.map(msg => ({
      ...msg,
      timestamp: formatTimestamp(msg.timestamp)
    }));
    setMessages(formattedMessages);
    socketRef.current.emit('markAsRead', username);
  };

  const sendMessage = () => {
    if (input.trim() && selectedUser) {
      const messageData = {
        sender: 'p2p_superadmin',
        recipient: selectedUser,
        message: input,
        timestamp: new Date().toISOString()
      };
      socketRef.current.emit('message', messageData);
      setInput('');
    }
  };

  const formatTimestamp = (timestamp) => {
    const timeOnly = timestamp.split('T')[1].split('.')[0];
    return timeOnly;
  };

  return (
    <div className="admin-chat-container"><br></br>
    <Logo />
    <div className="admin-chat-header">
      <h1>Admin Chat</h1>
      <div className="user-list">
        {users.map((user, index) => (
          <div 
            key={index} 
            className={`user ${user === selectedUser ? 'active' : ''}`}
            onClick={() => selectUser(user)}
          >
            {user}
          </div>
        ))}
      </div>
    </div>
      <div className="split">
      <div className="chat-box">
        {messages.map((msg, index) => (
          <div key={index} className={`chat-bubble ${msg.sender === 'p2p_superadmin' ? 'self' : ''}`}>
            <p>{msg.message}</p>
            <span className="timestamp">{msg.timestamp}</span>
          </div>
        ))}
      </div>
      <div className="chat-input">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type a message..."
        />
        <button onClick={sendMessage}>
        <img width="20" height="20" src="https://img.icons8.com/external-others-iconmarket/64/external-paperplane-essential-others-iconmarket-3.png" alt="external-paperplane-essential-others-iconmarket-3"/>
        </button>
      </div>
      </div>
    </div>
  );
};

export default AdminChat;
