import React, { useState } from 'react';
import '../styles/FAQ.css';
import homeImage from '../assets/images/tyre-img.jpg';
import Logo from '../components/Logo';
import Navbar from '../components/Navbar';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  // Arrays of questions and answers
  const questions = [
    'How do I track my shipment?',
    'What countries do you ship to?',
    'What is the estimated delivery time for my shipment?',
    'Do you offer insurance for shipments?',
    'Can I change the delivery address after placing an order?',
    'How can I contact customer support?',
    'Do you provide packaging materials?',
    'What payment methods do you accept?',
  ];

  const answers = [
    'To track your shipment, you can visit our website and enter your tracking number...',
    'We offer shipping services to a wide range of countries, including...',
    'Delivery times vary depending on the destination and shipping method. Typically, deliveries...',
    'Yes, we offer shipping insurance for added protection of your goods during transit...',
    'In most cases, we allow changes to the delivery address before the shipment is dispatched...',
    'You can contact our customer support team via email, phone, or live chat on our website...',
    'Yes, we offer packaging materials such as boxes, tape, and bubble wrap for your convenience...',
    'We accept various payment methods including credit/debit cards, PayPal, bank transfers, and more...',
  ];

  const handleQuestionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq">
      <Logo />
           <div className="home">
                <div className="overlay"><h2>Frequently Asked Questions</h2></div>
                <img className='homeimage' src={homeImage} loading='lazy' alt="faq-home-img" />
            </div>
    <div className="faq-container">
      <div className="faq-list">
        {questions.map((question, index) => (
          <div key={index} className="faq-item">
            <div className="question" onClick={() => handleQuestionClick(index)}>
              <p>{question}</p>
              <div className="icon">{activeIndex === index ? '-' : '+'}</div>
            </div>
            {activeIndex === index && (
              <p className="answer">
                {answers[index]}
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
    <Navbar />
    </div>
  );
};

export default FAQ;
