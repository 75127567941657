import React, { useState } from 'react';
import axios from 'axios';
import '../styles/Register.css';
import { Link, useNavigate } from 'react-router-dom'; 
import { toast } from 'react-hot-toast';
import Logo from '../components/Darklogo';
import loginFomrFlexImg from '../assets/images/taping.jpg';

const Register = () => {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    username: '',
    email: '',
    accounttype: '',
    level: '',
    address: '',
    phonenumber: '',
    countrycode: '',
    password: '',
    confirmpassword: '',
    agreement: false,
    referralcode: ''
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phone) => {
    const cleanedPhone = phone.replace(/\D/g, '');
    const expectedLength = 9;
    return cleanedPhone.length === expectedLength;
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.agreement) {
      toast.error('You must agree to the terms and conditions to register.');
      return;
    }

    if (formData.username.length <= 5) {
      toast.error('Username must be more than 5 characters long.');
      return;
    }
    

    if (!validateEmail(formData.email)) {
      toast.error('Invalid email format.');
      return;
    }

    if (!validatePhoneNumber(formData.phonenumber)) {
      toast.error('Invalid phone number format.');
      return;
    }

    if (formData.password.length < 8) {
      toast.error('Password must be at least 8 characters long.');
      return;
    }

    if (formData.password !== formData.confirmpassword) {
      toast.error('Passwords do not match.');
      return;
    }

    if (!formData.countrycode) {
      toast.error('Please select a valid country code.');
      return;
    }

    if (!formData.accounttype) {
      toast.error('Please select a valid account type.');
      return;
    }

    try {
      const response = await axios.post('https://p2pbackendserver-six.vercel.app/register', formData);
      console.log('Registration successful:', response.data);
      toast.success('Registration successful');
      setTimeout(() => {
        navigate('/login');
      }, 3000);
      resetForm();
    } catch (error) {
      console.error('Registration failed:', error);
      if (error.response) {
        if (error.response.status === 400) {
          toast.error(error.response.data.error);
        } else {
          toast.error('An unexpected error occurred');
        }
      } else if (error.request) {
        toast.error('No response received from server');
      } else {
        toast.error('Error setting up the request');
      }
    }
  };

  const resetForm = () => {
    setFormData({
      firstname: '',
      lastname: '',
      username: '',
      email: '',
      accounttype: '',
      level: '',
      address: '',
      phonenumber: '',
      countrycode: '',
      password: '',
      confirmpassword: '',
      agreement: false,
      referralcode: ''
    });
  };

  return (
    <div className="register-form-container">
      <Logo />
      <div className="register-form-pad">
        <form className="register-form" onSubmit={handleSubmit}>
          <div className='register-title'><h2>Create an account</h2></div>
          <div className='inner'>
            <div className="name-container">
              <input
                type="text"
                placeholder="First Name"
                name="firstname"
                value={formData.firstname}
                onChange={handleChange}
              />
              <input
                type="text"
                placeholder="Last Name"
                name="lastname"
                value={formData.lastname}
                onChange={handleChange}
              />
            </div>
            <input
              type="text"
              placeholder="Username"
              name="username"
              value={formData.username}
              onChange={handleChange}
            />
            <input
              type="email"
              placeholder="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            <input
              type="text"
              placeholder="Address"
              name="address"
              value={formData.address}
              onChange={handleChange}
            />
            <div className="phone-container">
              <select className='c-code' name="countrycode" value={formData.countrycode} onChange={handleChange}>
                <option value="">+...</option>
                <option value="+233">+233</option>
                <option value="+234">+234</option>
              </select>
              <input
                type="text"
                placeholder="Phone Number"
                name="phonenumber"
                value={formData.phonenumber}
                onChange={handleChange}
              />
            </div>
            <div className="select-container">
              <select
                name="accounttype"
                value={formData.accounttype}
                onChange={handleChange}
                className='c-code'
              >
                <option value="" disabled>Account Type</option>
                <option value="personal">Personal</option>
                <option value="business">Business</option>
              </select>
              <select
                name="level"
                value={formData.level}
                onChange={handleChange}
                className='c-code'
              >
                <option value="">Level</option>
                <option value="beginner">Beginner</option>
                <option value="intermediate">Intermediate</option>
                <option value="advanced">Advanced</option>
              </select>
            </div>
            <input
              type="password"
              placeholder="Password"
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
            <input
              type="password"
              placeholder="Confirm Password"
              name="confirmpassword"
              value={formData.confirmpassword}
              onChange={handleChange}
            />
            <input
              type="text"
              placeholder="Referral Code (optional)"
              name="referralcode"
              value={formData.referralcode}
              onChange={handleChange}
            />
            <div className='agreement-section'>
              <input
                type="checkbox"
                id="agreement"
                name="agreement"
                checked={formData.agreement}
                onChange={handleChange}
              />
              <label htmlFor="agreement">
                By creating an account, you agree to P2P's <Link className='terms-link' to="/legal">Terms of Services and Privacy Policy</Link>
              </label>
            </div>
            <button className='register-button' type="submit">Register</button>
            <p className='noregtag'>
              Already have an account? <Link className='noreg' to="/login">Sign in</Link>
            </p>
          </div>
        </form>
        <div className="register-image-container">
          <img loading='lazy' src={loginFomrFlexImg} alt="Register-img" />
        </div>
      </div>
    </div>
  );
};

export default Register;
