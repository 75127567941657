import React, { useState, useEffect } from 'react';
import '../styles/QuotePage.css';
import shipping from '../assets/images/shipping-vid.mp4';
import Logo from '../components/Logo';
import Navbar from '../components/Navbar';
import { toast } from 'react-hot-toast';

function QuotePage() {
  const [originCountry, setOriginCountry] = useState('');
  const [destinationCountry, setDestinationCountry] = useState('');
  const [shipmentWeightKg, setShipmentWeightKg] = useState('');
  const [shipmentWeightLbs, setShipmentWeightLbs] = useState('');
  const [totalCost, setTotalCost] = useState('');

  useEffect(() => {
    if (shipmentWeightKg === '') {
      setShipmentWeightLbs('');
      setTotalCost('');
      return;
    }

    const weightKg = parseFloat(shipmentWeightKg);
    if (isNaN(weightKg) || weightKg <= 0) {
      setShipmentWeightLbs('');
      setTotalCost('');
      return;
    }

    const lbsEquivalent = weightKg * 2.20462; // 1 kilogram = 2.20462 pounds
    setShipmentWeightLbs(lbsEquivalent.toFixed(2)); // Round to 2 decimal places

    // Check for weight threshold (e.g., 20 kg)
    if (weightKg > 20) {
      toast.error('Weight exceeds the maximum allowed limit.');
      setTotalCost('');
      return;
    }

    // Calculate shipping rate based on weight range
    let shippingRate = 0;
    if (weightKg <= 2) {
      shippingRate = 30;
    } else if (weightKg <= 4) {
      shippingRate = 55;
    } else if (weightKg <= 6) {
      shippingRate = 75;
    } else if (weightKg <= 8) {
      shippingRate = 90;
    } else if (weightKg <= 11) {
      shippingRate = 110;
    } else if (weightKg <= 14) {
      shippingRate = 130;
    } else if (weightKg <= 16) {
      shippingRate = 150;
    } else if (weightKg <= 18) {
      shippingRate = 170;
    } else {
      shippingRate = 200;
    }

    // Calculate total cost
    const total = shippingRate;
    setTotalCost(total); // Total cost is just the shipping rate
  }, [shipmentWeightKg]); // Update when shipmentWeightKg changes

  return (
    <div className="main-quote-container">
      <Logo />
      <div className="schedule-home-section">
        <video loading='lazy' src={shipping} autoPlay loop muted preload="none" />
        <div className="schedule-overlay">
          <div className="schedule-content">
            <h1>Quote and Pricings</h1>
            <p>Calculate the exact amount you would pay to have your order shipped</p>
          </div>
        </div>
      </div>

      <section className="q-quotation-calculator">
        <div className="quotation-inner">
          <div className="main-quotation-input-group">
            <h2 style={{ textAlign: "center", color: "white" }}>Get a quote</h2>
            <div className="quotation-input-group-top">
              <select
                value={originCountry}
                onChange={(e) => setOriginCountry(e.target.value)}
                className='page-location-select'
              >
                <option value="">Select Origin Country</option>
                {/* <option value="Ghana">Ghana</option> */}
                <option value="UK">UK</option>
                {/* <option value="US" disabled>US</option> */}
              </select>
              <select
                value={destinationCountry}
                onChange={(e) => setDestinationCountry(e.target.value)}
                className='page-desti-location-select'
              >
                <option value="">Select Destination Country</option>
                <option value="Ghana">Ghana</option>
                {/* <option value="UK">UK</option>
                <option value="US" disabled>US</option> */}
              </select>
            </div>

            <div className="quotation-page-input-group-middle">
              <input
                type="number"
                placeholder="Weight (kg)"
                value={shipmentWeightKg}
                onChange={(e) => setShipmentWeightKg(e.target.value)}
              />
              <input
                type="number"
                placeholder="Weight (lbs)"
                value={shipmentWeightLbs}
                disabled
                className='lbs'
              />
            </div>

            <div className="quotation-input-group-bottom">
              <label style={{ color: 'white' }}>Total</label>
              <input
                type="number"
                placeholder="Total Cost"
                value={totalCost}
                disabled
                className='total-cost'
              />
            </div>
          </div>
        </div>
      </section>

      <section className="responsive-tables">
        <h2>Popular Weight Details</h2>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Item Name</th>
                <th>Weight (lbs)</th>
                <th>Weight (Kg)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Cell Phone</td>
                <td>1.00</td>
                <td>0.45</td>
              </tr>
              <tr>
                <td>Laptop</td>
                <td>5.00</td>
                <td>2.27</td>
              </tr>
              <tr>
                <td>Clothing</td>
                <td>0.40</td>
                <td>0.18</td>
              </tr>
              <tr>
                <td>Shoe</td>
                <td>2.00</td>
                <td>0.91</td>
              </tr>
              <tr>
                <td>Camera - Canon EOS Rebel</td>
                <td>3.50</td>
                <td>1.59</td>
              </tr>
              <tr>
                <td>Tablet</td>
                <td>1.50</td>
                <td>0.68</td>
              </tr>
              <tr>
                <td>PS5/Xbox</td>
                <td>9.00</td>
                <td>4.08</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <Navbar />
    </div>
  );
}

export default QuotePage;
