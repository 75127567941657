import React, { useState, useEffect } from 'react';
import '../styles/ConnectionStatus.css';

const ConnectionStatus = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  return (
    <div className={`connection-status ${isOnline ? 'online' : 'offline'}`}>
      {!isOnline && <p>It appears that you're not connected to the internet</p>}
    </div>
  );
};

export default ConnectionStatus;
