// Dashboard.jsx
import React from 'react';
import { FaTruck, FaSpinner, FaCheckCircle } from 'react-icons/fa';
import './Dashstats.css';

const Dashboard = () => {
  return (
    <div className="dashboard">
      <div className="box">
        <FaTruck className="icon" />
        <p className="title">Total Deliveries</p>
        <p className="figure">0</p>
      </div>
      <div className="box">
        <FaSpinner className="icon" />
        <p className="title">In Progress</p>
        <p className="figure">0</p>
      </div>
      <div className="box">
        <FaCheckCircle className="icon" />
        <p className="title">Completed</p>
        <p className="figure">0</p>
      </div>
    </div>
  );
};

export default Dashboard;
