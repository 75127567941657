import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/Profile.css';
import '../styles/CopyAddress.css';
import profile from '../assets/images/profile-img-orange.png';
import closeProfile from '../assets/images/close-profile-img.png';
import ghflag from '../assets/images/gh-flag-img.jpg';
import njflag from '../assets/images/naija-flag-img.jpg';
import { toast } from 'react-hot-toast';
import axios from 'axios';

const Profile = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [address, setAddress] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [showCopySection, setShowCopySection] = useState(false);
  const [showdashbutton, setshowdashbutton] = useState(false);
  const [changeIcon, setChangeIcon] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [chooseFlag, setChooseFlag] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleDropdownToggle = () => {
    setChangeIcon(!changeIcon);
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const checkLoggedIn = () => {
      const token = localStorage.getItem('token');
      return token !== null;
    };

    const getUsername = () => localStorage.getItem('username');
    const getEmail = () => localStorage.getItem('email');
    const getFlag = () => localStorage.getItem('countrycode');

    setIsLoggedIn(checkLoggedIn());
    setUsername(getUsername());
    setEmail(getEmail());
    setChooseFlag(getFlag());
  }, []);


  useEffect(() => {
    const isadmin = localStorage.getItem('username') === 'p2p_superadmin';
    if(isadmin){
      setshowdashbutton(true)
    }
  }, [isLoggedIn]);
  
  const handleLogout = async () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('_id');
    toast.success('Logout successful');
    window.location.reload();
  };

  const dropDowAaddresscopy = async () => {
    const response = await axios.get('https://p2pbackendserver.vercel.app/getaddress');
    setAddress(response.data.address);
    setShowCopySection(!showCopySection);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(address);
    setShowMessage(true);
    setTimeout(() => setShowMessage(false), 3000);
  };

  const handleNav = (url) => {
    const endpoints = ["profile", "tracking-page"];
    if (endpoints.includes(url)) {
      navigate(`/${url}`);
    }
  };

  return (
    <div className="profile-container">
      <div className="profile-text">
        {isLoggedIn ? (
          <div className="flag-container">
            <p className="profile-text-status">{username}! </p>
            {isLoggedIn && chooseFlag  === '+233' ? (
              <img loading="lazy" className="flag" src={ghflag} alt="gh Profile Icon" />
            ) : (
              <img loading="lazy" className="flag" src={njflag} alt="nj Profile Icon" />
            )}
          </div>
        ) : (
          <p className="profile-text-status">not signed in</p>
        )}
      </div>
      <div className="profile-icon" onClick={handleDropdownToggle}>
        {changeIcon ? (
          <img loading="lazy" src={profile} alt="Profile Icon" />
        ) : (
          <img loading="lazy" src={closeProfile} alt="Profile Icon" />
        )}
      </div>
      {isDropdownOpen && (
        <div className="dropdown-menu">
          <div className="user-info">
            <img loading="lazy" className="user-image" src={profile} alt="User" />
          </div>
          <div className="logout-section">
            {isLoggedIn ? (
              <>
                <div className="desktop-profile-link-upper">
                  <p className="profile-text-status"><strong>{username}!</strong></p>
                </div>

                <div className="desktop-profile-link-upper">
                  <a className="profile-text-status">{email}</a>
                </div>
                <hr />

                <div className="desktop-profile-link-upper">
                <img width="18" height="18" src="https://img.icons8.com/ios/50/1A1A1A/admin-settings-male.png" alt="login-rounded" />
                  <a className="profile-text-status" onClick={() => handleNav("profile")}>Profile</a>
                </div>
                <hr />

                <div className="desktop-profile-link-upper">
                <img width="18" height="18" src="https://img.icons8.com/ios/50/login-rounded-right--v1.png" alt="login-rounded" />
                  <a className="profile-text-status" onClick={() => handleNav("tracking-page")}>Tracking</a>
                </div>
                <hr />

                {showdashbutton && (
                <>
                <div className="desktop-profile-link-upper">
                <img width="18" height="18" src="https://img.icons8.com/ios/50/control-panel--v2.png" alt="login-rounded" />
                <Link className="profile-text-status" to="/dashboard">Admin Dashboard</Link>
                </div>
                <hr />
                </>
              )}

                <div className="desktop-profile-link-upper">
                <img width="16" height="16" src="https://img.icons8.com/ios/50/FF0000/exit--v1.png" alt="login-rounded" />
                  <Link className="profile-text-status" style={{ color: "red" }} onClick={handleLogout}>Logout</Link>
                </div>
                <hr />
              </>
            ) : (
              <div className="tracking-link">
                <div className="desktop-profile-link">
                  <img width="20" height="20" src="https://img.icons8.com/ios-filled/50/order-shipped.png" alt="order-shipped" />
                  <Link to="/login">Login</Link>
                </div>

                <div className="desktop-profile-link">
                  <img width="20" height="20" src="https://img.icons8.com/ios/50/login-rounded-right--v1.png" alt="login-rounded" />
                  <Link to="/tracking-page">Tracking</Link>
                </div><br/>

              </div>
              
            )}
          </div>
          <br />
          {isLoggedIn && !showCopySection && (
            <button onClick={dropDowAaddresscopy}>Get Address</button>
          )}
          {showCopySection && isLoggedIn && (
            <div className="profile-copy-address-container">
              <div className="profile-copy-address">
                <p>{address}</p>
                <svg
                  onClick={copyToClipboard}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  color="#fffff"
                  fill="none"
                >
                  <path
                    d="M16 2H12C9.17157 2 7.75736 2 6.87868 2.94627C6 3.89254 6 5.41554 6 8.46154V9.53846C6 12.5845 6 14.1075 6.87868 15.0537C7.75736 16 9.17157 16 12 16H16C18.8284 16 20.2426 16 21.1213 15.0537C22 14.1075 22 12.5845 22 9.53846V8.46154C22 5.41554 22 3.89254 21.1213 2.94627C20.2426 2 18.8284 2 16 2Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                  />
                  <path
                    d="M18 16.6082C17.9879 18.9537 17.8914 20.2239 17.123 21.0525C16.2442 22 14.8298 22 12.0011 22H8.00065C5.17192 22 3.75754 22 2.87878 21.0525C2.00003 20.2239 1.91295 18.9537 1.90088 16.6082"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {showMessage && <p>Address copied to clipboard!</p>}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Profile;
