// src/components/TrackingInput.js
import React, { useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import '../styles/Trackingpage.css';
import Logo from '../assets/images/logoblacktext.png';

const TrackingInput = () => {
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const trackingNumber = e.target.elements.trackingNumber.value.trim();

    if (!trackingNumber) {
      toast.error('Please enter a tracking number');
      return;
    }

    try {
      const response = await axios.post('https://p2pbackendserver-six.vercel.app/check-tracking', { trackingNumber });
      navigate(`/tracking/${trackingNumber}`, { state: { trackingInfo: response.data } });
    } catch (error) {
      console.error('Tracking failed:', error);
      if (error.response && error.response.status === 404) {
        toast.error('Tracking number not found');
      } else if (error.response) {
        toast.error('An unexpected error occurred');
      } else if (error.request) {
        toast.error('No response received from server');
      } else {
        toast.error('Error setting up the request');
      }
    }
  };

  return (
    <div className='main-input'>
      <div className='input-logo'>
          <Link to="/">
            <img loading='lazy' src={Logo} alt='input-logo' />
          </Link>
        </div>


    <div className='crossfireui'>
        <h2>Track your order</h2>
      <form onSubmit={handleSubmit}>
        <input type='text' name="trackingNumber" className='inner-main-input' placeholder='Track your order' />
        <button type='submit' className='inner-main-button'>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} color={"#ffffff"} fill={"none"}>
            <path d="M19.5 17.5C19.5 18.8807 18.3807 20 17 20C15.6193 20 14.5 18.8807 14.5 17.5C14.5 16.1193 15.6193 15 17 15C18.3807 15 19.5 16.1193 19.5 17.5Z" stroke="currentColor" strokeWidth="1.5" />
            <path d="M9.5 17.5C9.5 18.8807 8.38071 20 7 20C5.61929 20 4.5 18.8807 4.5 17.5C4.5 16.1193 5.61929 15 7 15C8.38071 15 9.5 16.1193 9.5 17.5Z" stroke="currentColor" strokeWidth="1.5" />
            <path d="M14.5 17.5H9.5M19.5 17.5H20.2632C20.4831 17.5 20.5931 17.5 20.6855 17.4885C21.3669 17.4036 21.9036 16.8669 21.9885 16.1855C22 16.0931 22 15.9831 22 15.7632V13C22 9.41015 19.0899 6.5 15.5 6.5M2 4H12C13.4142 4 14.1213 4 14.5607 4.43934C15 4.87868 15 5.58579 15 7V15.5M2 12.75V15C2 15.9346 2 16.4019 2.20096 16.75C2.33261 16.978 2.52197 17.1674 2.75 17.299C3.09808 17.5 3.56538 17.5 4.5 17.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M2 7H8M2 10H6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
      </form>
      </div>
    </div>
  );
};

export default TrackingInput;
