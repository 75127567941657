import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook
import '../styles/NotSignedInAsUser.css';

const NotSignedInAsUser = () => {
    const navigate = useNavigate(); // Initialize the navigate function

    const handleNavigate = () => {
        navigate('/login'); // Navigate to the login page
    };

    return (
        <div className="not-signed-in-container">
            <div className="notification-box">
                <h1 className="not-signed-in-title">Not signed in as User 😞</h1>
                <p className="sign-in-link" onClick={handleNavigate}>Sign in</p>
            </div>
        </div>
    );
};

export default NotSignedInAsUser;
