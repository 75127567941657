import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/ScheduleAdmin.css'; // Import the CSS file for styling

const ScheduleAdmin = () => {
  const [schedules, setSchedules] = useState([]);
  const [form, setForm] = useState({
    pickUpLocation: '',
    deliveryLocation: '',
    estimatedTime: '',
    service: '',
  });
  const [isEditing, setIsEditing] = useState(false);
  const [currentId, setCurrentId] = useState(null);

  useEffect(() => {
    fetchSchedules();
  }, []);

  const fetchSchedules = async () => {
    try {
      const response = await axios.get('https://p2pbackendserver-six.vercel.app/api/schedules');
      setSchedules(response.data);
    } catch (error) {
      console.error('Error fetching schedules:', error);
    }
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isEditing) {
      await axios.put(`https://p2pbackendserver-six.vercel.app/api/schedules/${currentId}`, form);
    } else {
      await axios.post('https://p2pbackendserver-six.vercel.app/api/schedules', form);
    }
    setForm({ pickUpLocation: '', deliveryLocation: '', estimatedTime: '', service: '' });
    setIsEditing(false);
    setCurrentId(null);
    fetchSchedules();
  };

  const handleEdit = (schedule) => {
    setForm(schedule);
    setIsEditing(true);
    setCurrentId(schedule._id);
  };

  const handleDelete = async (id) => {
    await axios.delete(`https://p2pbackendserver-six.vercel.app/api/schedules/${id}`);
    fetchSchedules();
  };

  return (
    <div className="admin-container">
      <h1>Schedule Admin</h1>
      <p style={{color: "black"}}>Create, edit, update and delete schedules here</p>
      <form onSubmit={handleSubmit} className="admin-form">
        <input
          type="text"
          name="pickUpLocation"
          placeholder="Pick Up Location"
          value={form.pickUpLocation}
          onChange={handleChange}
        />
        <input
          type="text"
          name="deliveryLocation"
          placeholder="Delivery Location"
          value={form.deliveryLocation}
          onChange={handleChange}
        />
        <input
          type="text"
          name="estimatedTime"
          placeholder="Estimated Time"
          value={form.estimatedTime}
          onChange={handleChange}
        />
        <input
          type="text"
          name="service"
          placeholder="Service"
          value={form.service}
          onChange={handleChange}
        />
        <button type="submit">{isEditing ? 'Update' : 'Create'}</button>
      </form>

      <h2>Schedules</h2>
      <ul className="schedule-list">
        {schedules.map((schedule) => (
          <li key={schedule._id} className="schedule-item">
            <div className="schedule-info">
              {schedule.pickUpLocation} to {schedule.deliveryLocation} at {schedule.estimatedTime} ({schedule.service})
            </div>
            <div className="schedule-actions">
              <button onClick={() => handleEdit(schedule)}>Edit</button>
              <button onClick={() => handleDelete(schedule._id)}>Delete</button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ScheduleAdmin;
