import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import logo from '../assets/images/logowhitetext.png';
import '../styles/Logo.css';
import { startTransition } from 'react'; // Import startTransition

function Logo() {
  const navigate = useNavigate(); // Use useNavigate hook

  const handleNavigation = () => {
    startTransition(() => {
      navigate('/'); // Navigate to the home page
    });
  };

  return (
    <div onClick={handleNavigation} className='logo-container' style={{ cursor: 'pointer' }}>
      <img loading='lazy' src={logo} alt='logo-main' />
    </div>
  );
}

export default Logo;
