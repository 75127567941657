import React from 'react';
import whatsappicon from '../assets/images/whatsappicon.png';
import '../styles/whatsappchat.css';

const WhatsappChat = () => {
  const handleWhatsAppClick = () => {
    const phoneNumber = '+233503890000';
    const message = 'Hello!';
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappURL, '_blank');
  };

  return (
    <div onClick={handleWhatsAppClick} className='whats-button '>
      <img src={whatsappicon} className='whats-icon' alt="WhatsApp Icon"/>
    </div>
  );
};


export default WhatsappChat;
