import React from 'react';
import '../styles/Slider.css';
import amazon from '../assets/images/amazon-img.png';
import ebay from '../assets/images/ebay-img.png';
import costco from '../assets/images/costco-img.png';
import walmart from '../assets/images/walmart-img.png';
import aliexpress from '../assets/images/aliexpress-img.png';
import jumia from '../assets/images/jumia-img.png';
import hp from '../assets/images/hp-img.png';
import apple from '../assets/images/apple-img.png';
import alibaba from '../assets/images/alibaba-img.png';
import samsung from '../assets/images/samsung-img.png';
import dell from '../assets/images/dell-img.png';
import target from '../assets/images/target-img.png';

const Slider = () => {
  const images = [amazon, ebay, costco, walmart, aliexpress, jumia, hp, apple, alibaba, samsung, dell, target];
  const imagelinks = [
    'https://www.amazon.com/', 'https://www.ebay.com/', 'https://www.costco.com/', 'https://www.walmart.com/',
    'https://www.aliexpress.com/', 'https://www.jumia.com/', 'https://www.hp.com/', 'https://www.apple.com/',
    'https://www.alibaba.com/', 'https://www.samsung.com/', 'https://www.dell.com/', 'https://www.target.com/'
  ];

  const allIcons = [...images, ...images]; // Duplicate icons for seamless effect

  return (
    <div className="slider-container">
      <div className="slider-tray">
        {allIcons.map((image, index) => (
          <div key={index} className="tray-icon">
            <a href={imagelinks[index % images.length]}>
              <img loading='lazy' src={image} alt="store-img" />
            </a>
          </div>
        ))}
      </div>
      <div className="slider-overlay"></div>
    </div>
  );
};

export default Slider;
