import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FiMapPin } from 'react-icons/fi';
import './Address.css';
import Usernavbar from './Usernavbar';

const Address = () => {
  const [addresses, setAddresses] = useState([]); 
  const [showMessage, setShowMessage] = useState(false);
  
  useEffect(() => {
    const getAddress = async () => {
      try {
        const response = await axios.get('https://p2pbackendserver-six.vercel.app/getaddress');
        const addressData = Array.isArray(response.data.address) 
          ? response.data.address 
          : [response.data.address];
          
        setAddresses(addressData); // Set addresses as an array
      } catch (error) {
        console.error('Error retrieving address:', error);
      }
    };

    getAddress();
  }, []);

  const copyToClipboard = (address) => {
    navigator.clipboard.writeText(address);
    setShowMessage(true);
    setTimeout(() => setShowMessage(false), 3000); 
  };

  return (
    <div className='address-box'>
       <Usernavbar />
      <div className='address-inner'>
      {addresses.length > 0 ? (
        addresses.map((address, index) => ( // Use index as key since addresses are strings
          <div key={index} className='indie-address-card'>
            <div className='address-icon'>
              <FiMapPin size={30} />
            </div>
            <div className='address-title'>
              <h3>UNITED KINGDOM</h3>
            </div>
            <div className='address-content'>
              <p>{address}</p>
            </div>
            <div className='address-copty-btn'>
              <button onClick={() => copyToClipboard(address)}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" color="#fffff" fill="none">
                  <path d="M16 2H12C9.17157 2 7.75736 2 6.87868 2.94627C6 3.89254 6 5.41554 6 8.46154V9.53846C6 12.5845 6 14.1075 6.87868 15.0537C7.75736 16 9.17157 16 12 16H16C18.8284 16 20.2426 16 21.1213 15.0537C22 14.1075 22 12.5845 22 9.53846V8.46154C22 5.41554 22 3.89254 21.1213 2.94627C20.2426 2 18.8284 2 16 2Z" stroke="currentColor" strokeWidth="1.5" />
                  <path d="M18 16.6082C17.9879 18.9537 17.8914 20.2239 17.123 21.0525C16.2442 22 14.8298 22 12.0011 22H8.00065C5.17192 22 3.75755 22 2.87878 21.0525C2 20.1049 2 18.5799 2 15.5298V14.4515C2 11.4014 2 9.87638 2.87878 8.92885C3.52015 8.2373 4.44682 8.05047 6.00043 8" stroke="currentColor" strokeWidth="1.5" />
                </svg>
              </button>
            </div>
            {showMessage && <p style={{ color: "black" }} className='address-success-modal'>Copied!</p>}
            <div className='blurb-address-info'>
              <p>Kindly be sure to use the address provided in the same format above when making self purchases</p>
            </div>
          </div>
        ))
      ) : (
        <div className='no-addresses'>We are revising our addresses, please be patient</div>
      )}
      </div>
    </div>
  );
};

export default Address;
