import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import '../styles/ArticleDetails.css';
import articleplaceholder from '../assets/images/placeholder-img.png';
import Logo from '../components/Darklogo';

const ArticleDetails = () => {
  const { id } = useParams();
  const [article, setArticle] = useState(null);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await axios.get(`https://p2pbackendserver-six.vercel.app/api/articles/${id}`);
        setArticle(response.data);
      } catch (error) {
        console.error('Failed to fetch article', error);
      }
    };

    fetchArticle();
  }, [id]);

  if (!article) {
    return <p>Loading...</p>;
  }

  return (
    <div className="article-details-container">

    <Logo />

      <h1>{article.title}</h1>
      <img src={articleplaceholder} alt="Article" className="article-details-image" />
      <p>{article.description}</p>
      <div className="article-content">{article.content}</div>
    </div>
  );
};

export default ArticleDetails;
