import React from 'react';

const NotSignedInAsAdmin = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center', height:'100vh' }}>
            <h1 style={{ fontSize: '3rem' }}>Not signed in as admin 😞</h1>
        </div>
    );
};

export default NotSignedInAsAdmin;
