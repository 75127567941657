import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import '../styles/ReadMore.css';
import leftarrow from '../assets/images/rightarrow.png'; // Replace with your actual icon path
// import exampleIcon from '../assets/example-icon.svg'; // Replace with your actual icon path

const ReadMoreDirection = () => {

 const navigate = useNavigate();
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0 },
  };



    const handlenav = () => {
    navigate('/process');
    }

  return (
    <div className='readmoreOuter'>
    <motion.div
      ref={ref}
      className="readmore-container"
      variants={containerVariants}
      initial="hidden"
      animate={inView ? 'visible' : 'hidden'}
    >
      <motion.div className="readmore-column" variants={itemVariants}>
        <h2>What we do?</h2>
        <motion.div variants={itemVariants}>
        <button onClick={handlenav} className="readmore-button">
          READ MORE
          <div className="button-icon-container">
          <img width="20" height="20" src={leftarrow} alt="long-arrow-right"/>
          </div>
        </button>
      </motion.div>
      </motion.div>
      <motion.div className="readmore-column" id='changetext' variants={itemVariants}>
        <p>
          We pick up packages ordered in the UK and deliver to Ghana. Whether you’re an individual or a business, we understand the importance of having your packages picked up and delivered on time. Trust us to deliver your packages locally with care.
        </p>
      </motion.div>

    </motion.div>
    </div>
  );
};

export default ReadMoreDirection;
