import React from 'react'
import '../styles/shipWithUs.css';
import Logo from '../components/Logo';
import Navbar from '../components/Navbar';

function ShipWithUs() {
  return (
    <div className='ship-with-us-container'>
      <Logo />

      <div className='ship-with-us-header'>
        <p> Welcome to P2plogisitcs find below the simple process to ship your items from th eUK with us</p>
      </div>

      <div className='ship-with-us-steps'>
      <h1>How it works</h1>
      </div>


      <div className='step'>
      <p></p>
      </div>


      <div className='step'>
      <p></p>
      </div>
  
      <Navbar />
    </div>
  )
}

export default ShipWithUs
