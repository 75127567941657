import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/Schedules.css';
import shipping from '../assets/images/shipping-vid.mp4';
import Logo from '../components/Logo';
import Navbar from '../components/Navbar';

const Schedule = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchSchedules();
  }, []);

  const fetchSchedules = async () => {
    try {
      const response = await axios.get('https://p2pbackendserver-six.vercel.app/api/schedules');
      setData(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching schedules:', error);
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
    filterData(e.target.value);
  };

  const filterData = (query) => {
    const filtered = data.filter(item =>
      Object.values(item).some(val =>
        val.toLowerCase().includes(query.toLowerCase())
      )
    );
    setFilteredData(filtered);
  };

  const handlePrint = () => {
    const printableContent = document.getElementById('schedule-printable-content');
    const originalDocument = document.body.innerHTML;
    const contentToPrint = printableContent.innerHTML;
    document.body.innerHTML = contentToPrint;
    window.print();
    document.body.innerHTML = originalDocument;
  };

  return (
    <div className="main-schedule-container">
      <Logo />
      <div className="schedule-home-section">
        <video loading='lazy' src={shipping} autoPlay loop muted preload="none" />
        <div className="schedule-overlay"></div>
        <div className="schedule-content">
          <h1>Schedules</h1>
        </div>
      </div>

      <div className="schedule-container">
        <div className="search-input">
          <input type="text" placeholder="filter..." value={searchQuery} onChange={handleInputChange} />
          <img loading='lazy' onClick={handlePrint} width="30" height="30" src="https://img.icons8.com/dotty/80/1b2634/print.png" alt="print-img" />
        </div>
        <div id="schedule-printable-content">
          {isLoading ? (
            <p style={{color: 'black'}}>Loading schedules...</p>
          ) : (
            <>
              <table className="schedule-table">
                <thead>
                  <tr>
                    <th>Pick up location</th>
                    <th>Delivery location</th>
                    <th>Estimated time of arrival</th>
                    <th>Service</th>
                  </tr>
                </thead>
                <tbody>
                  {(filteredData.length > 0 ? filteredData : data).map((item, index) => (
                    <tr key={index}>
                      <td>{item.pickUpLocation}</td>
                      <td>{item.deliveryLocation}</td>
                      <td>{item.estimatedTime}</td>
                      <td>{item.service}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {(filteredData.length === 0 && data.length === 0) && (
                <p style={{color: "black"}}>No schedules currently available.</p>
              )}
            </>
          )}
        </div>
      </div>
      <Navbar />
    </div>
  );
};

export default Schedule;
