import React, {useState, useEffect} from 'react';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import '../styles/CreateTracking.css';

export default function GenerateTracking() {
    const [trackingInfo, setTrackingInfo] = useState({
        trackingNumber: '',
        productName: '',
        email: '',
        estimatedTimeOfArrival: '',
        orderStatus: '',
        timestamp: ''
    });
    const [generatedId, setGeneratedId] = useState('');
    const [trackingData, setTrackingData] = useState([]);
    const [editIndex, setEditIndex] = useState(null);
    

    const generateId = () => {
        const year = new Date().getFullYear().toString().substr(-2);
        const uniqueId = Math.random().toString(36).substr(2, 10);
        const trackingId = `p2p${year}-${uniqueId}`;
        setGeneratedId(trackingId);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!trackingInfo.trackingNumber || !trackingInfo.productName || !trackingInfo.email || !trackingInfo.estimatedTimeOfArrival || !trackingInfo.orderStatus) {
            toast.error('Please fill in all fields.');
            return;
        }
        try {
            const updatedTrackingInfo = {
                ...trackingInfo,
                timestamp: new Date().toISOString() // Set the current timestamp
            };
            console.log("Submitting tracking info:", updatedTrackingInfo); // Debug log
            const response = await axios.post('https://p2pbackendserver-six.vercel.app/create-tracking', updatedTrackingInfo);
            console.log(response.data);
            toast.success('Tracking info successfully saved');
            setTrackingInfo({
                trackingNumber: '',
                productName: '',
                email: '',
                estimatedTimeOfArrival: '',
                orderStatus: '',
                timestamp: ''
            });
            fetchTrackingData(); // Refresh data
        } catch (error) {
            console.error('Error updating tracking information:', error);
            toast.error('Failed to save tracking info');
        }
    };


    
    useEffect(() => {
        fetchTrackingData();
    }, []);

    const fetchTrackingData = async () => {
        try {
            const response = await axios.get('https://p2pbackendserver-six.vercel.app/tracking-info');
            setTrackingData(response.data);
        } catch (error) {

            console.error('Error fetching tracking information:', error);
        }
    };

    
    const handleUpdate = async (e) => {
        e.preventDefault();
        if (!trackingInfo.trackingNumber || !trackingInfo.productName || !trackingInfo.email || !trackingInfo.estimatedTimeOfArrival || !trackingInfo.orderStatus) {
            toast.error('Please fill in all fields.');
            return;
        }
        try {
            const updatedTrackingInfo = {
                ...trackingInfo,
                timestamp: new Date().toISOString() // Update the current timestamp
            };
            const response = await axios.put(`https://p2pbackendserver-six.vercel.app/update-tracking/${trackingData[editIndex]._id}`, updatedTrackingInfo);
            console.log(response.data);
            toast.success('Tracking info successfully updated');
            setEditIndex(null);
            setTrackingInfo({
                trackingNumber: '',
                productName: '',
                email: '',
                estimatedTimeOfArrival: '',
                orderStatus: '',
                timestamp: ''
            });
            fetchTrackingData(); // Refresh data
        } catch (error) {
            console.error('Error updating tracking information:', error);
            toast.error('Failed to update tracking info');
        }
    };
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setTrackingInfo(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(generatedId);
        toast.success('Tracking ID copied to clipboard!');
    };

    const handleEdit = (index) => {
        setEditIndex(index);
        setTrackingInfo(trackingData[index]);
    };


  return (
    <div className='create-tracking-container'>
        <div className="tracking-form-section">
                <button className='generate-btn' onClick={generateId}>Generate Tracking ID</button><br />
                {generatedId && (
                       <div className='generatedid'>
                       <p style={{ color: "black" }}>Generated Tracking ID: {generatedId}</p>
                       <svg onClick={handleCopyToClipboard} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" color="#fffff" fill="none">
                            <path d="M16 2H12C9.17157 2 7.75736 2 6.87868 2.94627C6 3.89254 6 5.41554 6 8.46154V9.53846C6 12.5845 6 14.1075 6.87868 15.0537C7.75736 16 9.17157 16 12 16H16C18.8284 16 20.2426 16 21.1213 15.0537C22 14.1075 22 12.5845 22 9.53846V8.46154C22 5.41554 22 3.89254 21.1213 2.94627C20.2426 2 18.8284 2 16 2Z" stroke="currentColor" strokeWidth="1.5" />
                            <path d="M18 16.6082C17.9879 18.9537 17.8914 20.2239 17.123 21.0525C16.2442 22 14.8298 22 12.0011 22H8.00065C5.17192 22 3.75755 22 2.87878 21.0525C2 20.1049 2 18.5799 2 15.5298V14.4515C2 11.4014 2 9.87638 2.87878 8.92885C3.52015 8.2373 4.44682 8.05047 6.00043 8" stroke="currentColor" strokeWidth="1.5" />
                        </svg>
                   </div>
                )}
                <form onSubmit={editIndex !== null ? handleUpdate : handleSubmit}>
                    <input type="text" name="trackingNumber" value={trackingInfo.trackingNumber} onChange={handleChange} placeholder="Tracking Number" />
                    <input type="text" name="productName" value={trackingInfo.productName} onChange={handleChange} placeholder="Product Name" />
                    <input type="text" name="email" value={trackingInfo.email} onChange={handleChange} placeholder="Email" />
                    <input type="text" name="estimatedTimeOfArrival" value={trackingInfo.estimatedTimeOfArrival} onChange={handleChange} placeholder="Estimated Time of Arrival" />
                    <select name="orderStatus" value={trackingInfo.orderStatus} onChange={handleChange}>
                        <option value="">Select Order Status</option>
                        <option value="Package received">Package received</option>
                        <option value="Package in transit">Package in transit</option>
                        <option value="Arrived in Accra">Arrived in Accra</option>
                        <option value="Delivered">Delivered</option>
                    </select>
                    <button className='tracking-submit-button' type="submit">{editIndex !== null ? 'Update' : 'Create'} Tracking Info</button>
                </form>
            </div>
      
    </div>
  )
}
