import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import '../styles/Reset.css'; // Import the CSS file
import Logo from '../components/Darklogo';
import Navbar from '../components/Navbar';

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const { token } = useParams();
    const navigate = useNavigate();

    const handleChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://p2pbackendserver-six.vercel.app/reset-password', { token, password });
            toast.success(response.data.message);
            setTimeout(() => {
                navigate('/login');
            }, 3000);
        } catch (error) {
            console.error('Reset Password error:', error);
            toast.error('Failed to reset password. Please try again.');
        }
    };

    return (
        <div className="auth-container">
            <Logo />
            <form className="auth-form" onSubmit={handleSubmit}>
                <h2>Reset Password</h2>
                <input
                    type="password"
                    placeholder="Enter your new password"
                    name="password"
                    value={password}
                    onChange={handleChange}
                />
                <button type="submit">Reset Password</button>
            </form>
            <Navbar />
        </div>
    );
};

export default ResetPassword;
