import React, { useState, useEffect } from 'react';
import '../styles/AnimatedTexts.css';

const AnimatedText = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const handleResize = () => {
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const calculateFontSize = () => {
    const maxFontSize = 3.5; // Maximum font size in vw units
    const minFontSize = 0.4; // Minimum font size in vw units
    const scrollRange = windowHeight * 2; // Adjust this value to control the range of scroll
    const halfScrollRange = scrollRange / 2;
    const scrollProgress = scrollPosition % scrollRange;

    if (scrollProgress <= halfScrollRange) {
      return minFontSize + ((maxFontSize - minFontSize) * scrollProgress) / halfScrollRange;
    } else {
      return maxFontSize - ((maxFontSize - minFontSize) * (scrollProgress - halfScrollRange)) / halfScrollRange;
    }
  };

  const fontSize = calculateFontSize();

  return (
    <div className="animated-text">
      <h1 style={{ fontSize: `${fontSize}vw` }}>
        YOU DO THE <span>SHOPPING!</span>, WE DO THE <span>SHIPPING!</span>
      </h1>
    </div>
  );
};

export default AnimatedText;
