import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../assets/images/logoblacktext.png';
import '../styles/Darklogo.css';
import { startTransition } from 'react'; // Import startTransition

function Darklogo() {
  const navigate = useNavigate(); // Use useNavigate hook

  const handleNavigation = () => {
    startTransition(() => {
      navigate('/'); // Navigate to the home page
    });
  };

  return (
    <div onClick={handleNavigation} className='logo-container' style={{ cursor: 'pointer' }}>
      <img loading='lazy' src={logo} alt='P2P-logo-img' />
    </div>
  );
}

export default Darklogo;
