import React, { useState, useEffect } from 'react';
import { FiCopy } from 'react-icons/fi';
import './Promotion.css';


const Promotion = () => {
  const [couponCode, setCouponCode] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  // Generate the coupon code when the component is mounted
  useEffect(() => {
    const generateCoupon = () => {
      const code = Math.random().toString(36).substring(2, 10).toUpperCase();
      setCouponCode(code);
    };
    generateCoupon();
  }, []);

  // Function to copy the coupon code to clipboard
  const copyToClipboard = () => {
    navigator.clipboard.writeText(couponCode);
    setShowMessage(true);
    setTimeout(() => setShowMessage(false), 2000); // Message will disappear after 2 seconds
  };

  return (
    <div className="promotion-container">
      
      <h2 className="promotion-title">Special Offer Just for You!</h2>
      <p className="promotion-description">
        Use the coupon code below to get a discount on your next purchase!
      </p>
      
      <div className="coupon-section">
        <input
          type="text"
          value={couponCode}
          readOnly
          className="coupon-input"
        />
        <button className="copy-button" onClick={copyToClipboard}>
          <FiCopy size={18} />
        </button>
      </div>

      {showMessage && <p className="copy-message">Coupon copied to clipboard!</p>}
    </div>
  );
};

export default Promotion;
