import React, { useState, useEffect, useRef } from 'react';
import socketIOClient from 'socket.io-client';
import axios from 'axios';
import './Chat.css';

const ENDPOINT = 'http://localhost:5000';

const Chat = ({ username }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [error, setError] = useState(null);
  const socketRef = useRef();

  useEffect(() => {
    socketRef.current = socketIOClient(ENDPOINT);
    socketRef.current.emit('setUsername', username);

    // Fetch initial messages
    axios.get(`${ENDPOINT}/messages/${username}`)
      .then((response) => {
        console.log('frontend response:', response.data);
        const formattedMessages = response.data.map(msg => ({
          ...msg,
          timestamp: formatTimestamp(msg.timestamp) // Format timestamp here
        }));
        setMessages(formattedMessages);
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          setError(`Hello ${username}`);
        } else {
          setError('hi, How can we help?');
        }
      });

    // Handle incoming messages
    socketRef.current.on('message', (message) => {
      if (message.recipient === username || message.sender === username) {
        const formattedMessage = {
          ...message,
          timestamp: formatTimestamp(message.timestamp) // Format timestamp here
        };
        setMessages((prevMessages) => [...prevMessages, formattedMessage]);
      }
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, [username]);

  const sendMessage = () => {
    if (input.trim()) {
      const messageData = {
        sender: username,
        recipient: 'p2p_superadmin',
        message: input,
        timestamp: new Date().toISOString() // Use ISO format for consistent parsing
      };
      socketRef.current.emit('message', messageData);
      setInput('');
    }
  };

  const formatTimestamp = (timestamp) => {
    // Example: timestamp = '2024-07-08T21:24:39.497Z'
    const timeOnly = timestamp.split('T')[1].split('.')[0]; // Extract time part and remove milliseconds
    return timeOnly;
  };

  return (
    <div className="chat-container">
      <div className="chat-header">Help</div>
      <div className="chat-box">
        {error ? (
          <div className="error-message">{error}</div>
        ) : (
          messages.map((msg, index) => (
            <div key={index} className={`chat-bubble ${msg.sender === username ? 'self' : ''}`}>
              <p>{msg.message}</p>
              <span className="timestamp">{msg.timestamp}</span>
            </div>
          ))
        )}
      </div>
      <div className="chat-input">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type a message..."
        />
        <button onClick={sendMessage}>
        <img width="20" height="20" src="https://img.icons8.com/external-others-iconmarket/64/external-paperplane-essential-others-iconmarket-3.png" alt="external-paperplane-essential-others-iconmarket-3"/>
        </button>
      </div>
    </div>
  );
};

export default Chat;
