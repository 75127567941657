import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/Reset.css'; // Import the CSS file
import Logo from '../components/Darklogo';
import Navbar from '../components/Navbar';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://yourbackendserver.com/forgot-password', { email });
            toast.success(response.data.message);
            setTimeout(() => {
                navigate('/login');
            }, 3000);
        } catch (error) {
            console.error('Forgot Password error:', error);
            toast.error('Failed to send reset link. Please try again.');
        }
    };

    return (
        <div className="auth-container">
            <Logo />
            <form className="auth-form" onSubmit={handleSubmit}>
                <h2>Forgot Password</h2>
                <input
                    type="email"
                    placeholder="Enter your email"
                    name="email"
                    value={email}
                    onChange={handleChange}
                />
                <button type="submit">Send Reset Link</button>
                <p>Remember your password? <Link to="/login">Login</Link></p>
            </form>
            <Navbar />
        </div>
    );
};

export default ForgotPassword;
