import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom'; 
import '../styles/Login.css';
import { toast } from 'react-hot-toast';
import Logo from '../components/Darklogo';
import loginSideImage from '../assets/images/dragging.jpg';

const Login = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });
  const navigate = useNavigate();

  // Function to check if user is logged in
  const isLoggedIn = () => {
    const token = localStorage.getItem('token');
    return token !== null;
  };

  useEffect(() => {
    if (isLoggedIn()) {
      navigate('/');
    }
  }, [navigate]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('Form data:', formData);
      const response = await axios.post('https://p2pbackendserver-six.vercel.app/login/', formData);
      const { username, token, _id, email, message, countrycode } = response.data;

      console.log('with country code Response:', response.data);
  
      // Store token and username in localStorage
      localStorage.setItem('username', username)
      localStorage.setItem('token', token);
      localStorage.setItem('_id', _id);
      localStorage.setItem('email', email);
      localStorage.setItem('showModal', 'true');
      localStorage.setItem('countrycode', countrycode);
      
  
      toast.success(message);
      setTimeout(() => {
        navigate('/profile');
      }, 3000);
    } catch (error) {
      console.error('Login failed:', error);
      if (error.response) {
        if (error.response.status === 401) {
          toast.error(error.response.data.error);
        } else {
          toast.error('Invalid credentials provided');
        }
      } else if (error.request) {
        toast.error('No response received from server');
      } else {
        toast.error('Error setting up the request');
      }
    }
  };


  return (
    <div className="login-form-container">
      <Logo />
      <div className="login-form-pad">
        <form className="login-form" onSubmit={handleSubmit}>
          <h2>Login</h2>
          <input
            type="text"
            placeholder="Username"
            name="username"
            value={formData.username}
            onChange={handleChange}
          />
          <input
            type="password"
            placeholder="Password"
            name="password"
            value={formData.password} // Keeping your naming convention consistent
            onChange={handleChange}
          />
          <button className='login-button' type="submit">Login</button>

          <p className='noregtag'>
            Don't have an account? <Link className='noreg' to="/register">Register</Link>
          </p>
          <Link className='noreg' to={"/forgot-password"}>Forgot password?</Link>
        </form>
        <div className="login-image-container">
          <img src={loginSideImage} loading='lazy' alt="Login-img" />
        </div>
      </div>
    </div>
  );
};

export default Login;