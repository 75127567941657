import React, { useState } from 'react';
import axios from 'axios';

const AddAddress = () => {
  const [inputValue, setInputValue] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = async () => {
    try {
      // Make a POST request to the endpoint with the input value
      const response = await axios.post('https://p2pbackendserver-six.vercel.app/createaddress', { address: inputValue });
      console.log(response.data); // Assuming the response contains some success message
      setSuccessMessage('String submitted successfully');
 
      // Clear the input field after submission
      setInputValue('');

      // Hide the success message after 3 seconds
      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
    } catch (error) {
      console.error('Submission failed:', error);
      // Handle error if submission fails
    }
  };

  return (
    <div style={styles.container}>
      <input
        type="text"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        placeholder="Enter string to submit"
        style={styles.input}
      />
      <button onClick={handleSubmit} style={styles.button}>Submit</button>
      {successMessage && <p style={styles.successMessage}>{successMessage}</p>}
    </div>
  );
};

const styles = {
  container: {
    margin: '20px',
  },
  input: {
    padding: '10px',
    marginRight: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    fontSize: '16px',
  },
  button: {
    padding: '10px 20px',
    borderRadius: '5px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
  },
  successMessage: {
    marginTop: '10px',
    color: 'green',
    fontSize: '16px',
  },
};

export default AddAddress;
