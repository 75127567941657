import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/CopyAddress.css';

const CopyAddress = () => {
  const [address, setAddress] = useState('');
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  // Function to check if user is logged in
  const checkLoggedIn = () => {
    const token = localStorage.getItem('token');
    return token !== null;
  };

  const isLoggedIn = checkLoggedIn();

  useEffect(() => {
    // if (!isLoggedIn) return;

    // Make a GET request to retrieve the address from the server
    const getAddress = async () => {
      try {
        const checkifadmin = localStorage.getItem('username') === 'p2p_superadmin';
        if (checkifadmin) {
          setShowAddressModal(false);
        }else {

          setTimeout(() => {
            const shouldShowModal = localStorage.getItem('showModal') === 'true';
  
            if (shouldShowModal) {
              setShowAddressModal(true);
              localStorage.setItem('showModal', 'false');
            
            }}, 2000);
        }
       
        const response = await axios.get('https://p2pbackendserver-six.vercel.app/getaddress');
        setAddress(response.data.address);
      } catch (error) {
        console.error('Error retrieving address:', error);
      }
    };

    getAddress();
  }, [isLoggedIn]);

    const closeAddressModal = () => {
      setShowAddressModal(false);
    };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(address);
    setShowMessage(true);
    setTimeout(() => setShowMessage(false), 3000); // Hide message after 3 seconds
  };

  const handleModalClick = (event) => {
    // Prevent the click event from bubbling up to the overlay
    event.stopPropagation();
  };

  return (
    <>
      {showAddressModal && isLoggedIn && (
        <div className="address-modal-overlay" onClick={closeAddressModal}>
          <div className="address-modal-container" onClick={handleModalClick}>
            <p className='pran'>Copy your delivery address</p>
            <div className="address-modal" onClick={handleModalClick}>
            <img width="30" height="30"  src="https://img.icons8.com/sf-black/64/FFFFFF/address.png" alt="address"/>
              <p>{address}</p>
              <svg onClick={copyToClipboard} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" color="#fffff" fill="none">
                <path d="M16 2H12C9.17157 2 7.75736 2 6.87868 2.94627C6 3.89254 6 5.41554 6 8.46154V9.53846C6 12.5845 6 14.1075 6.87868 15.0537C7.75736 16 9.17157 16 12 16H16C18.8284 16 20.2426 16 21.1213 15.0537C22 14.1075 22 12.5845 22 9.53846V8.46154C22 5.41554 22 3.89254 21.1213 2.94627C20.2426 2 18.8284 2 16 2Z" stroke="currentColor" strokeWidth="1.5" />
                <path d="M18 16.6082C17.9879 18.9537 17.8914 20.2239 17.123 21.0525C16.2442 22 14.8298 22 12.0011 22H8.00065C5.17192 22 3.75755 22 2.87878 21.0525C2 20.1049 2 18.5799 2 15.5298V14.4515C2 11.4014 2 9.87638 2.87878 8.92885C3.52015 8.2373 4.44682 8.05047 6.00043 8" stroke="currentColor" strokeWidth="1.5" />
              </svg>
            </div>
            {showMessage && <p style={{ color: "black" }} className='address-success-modal'>Copied to clipboard!</p>}
          </div>
        </div>
      )}
    </>
  );
};

export default CopyAddress;
