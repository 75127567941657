import React from 'react';

import { ChatEngine } from 'react-chat-engine'

const SupportAdmin = () => {
  return (

    <ChatEngine 
      projectID={process.env.REACT_APP_P2P_CHATSUPPORT_ID}
      userName='p2pbot'
      userSecret='Client2024'
      height='calc(100vh - 12px)'
    />
  );
}

export default SupportAdmin;
