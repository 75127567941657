import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Usernavbar.css';


function Usernavbar() {
    const [userData, setUserData] = useState({});

    useEffect(() => {
        const token = localStorage.getItem('token');
      
        if (token) {
          axios
            .get('https://p2pbackendserver-six.vercel.app/api/user/profile', {
              headers: {
                'Authorization': `Bearer ${token}` 
              }
            })
            .then((response) => { 
              const userData = response.data;
              console.log('User data:', userData);
              setUserData(userData);
            })
            .catch((error) => {
              console.error('Error fetching user data:', error);
            });
        }
      }, []);
  return (
    <>
    <div className="user-profile-navbar">
        <button onClick={() => window.history.back()} className="back-button">Back</button>
        <div className='greeting'>
        <span>Hi <strong>{userData.firstname }</strong></span>
        <div className="user-icon">{userData.firstname?.[0].toUpperCase()}{userData.lastname?.[0].toUpperCase()}</div>
        </div>
      </div>
      
    </>
  )
}

export default Usernavbar
