import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import axios from 'axios'; // Import axios to make API requests
import '../styles/ForBetterExperience.css';
import loginSideImage from '../assets/images/homesections/login-sideimage.webp';

const ForBetterExperience = () => {
  const [shippingNeeds, setShippingNeeds] = useState('');
  const [shippingExperience, setShippingExperience] = useState('');
  const navigate = useNavigate();

  const updateUser = async () => {
    try {
      const userId = localStorage.getItem('_id');

      const response = await axios.put(`https://p2pbackendserver-six.vercel.app/betterexperience${userId}`, {
        accounttype: shippingNeeds,
        level: shippingExperience,
      });

      console.log('User updated successfully:', response.data);
    } catch (error) {
      console.error('Failed to update user:', error);
    }
  };

  const handleNext = async () => {
    if (shippingNeeds && shippingExperience) {
      try {
        // Update the user's shipping needs and experience level
        await updateUser();

        // Redirect to the login page
        navigate('/login');
      } catch (error) {
        alert('An error occurred while updating your information. Please try again.');
      }
    } else {
      alert('Please answer both questions before continuing.');
    }
  };

  return (
    <div className="for-better-experience-container">
      <div className="for-better-experience-form">
        <div className="for-better-experience-image-container">
          <img src={loginSideImage} loading='lazy' alt="for-better-expereience-img" />
        </div>
        <div className='for-better-ex-section accounttype'>
          <h1>For a Better Experience</h1>
          <h3>How would you describe your shipping needs?</h3>
          <label>
            <input
              type="radio"
              value="business"
              checked={shippingNeeds === 'business'}
              onChange={() => setShippingNeeds('business')}
            />
            Business
          </label>
          <label>
            <input
              type="radio"
              value="personal"
              checked={shippingNeeds === 'personal'}
              onChange={() => setShippingNeeds('personal')}
            />
            Personal
          </label>

          <h3>What is your experience level in shipping?</h3>
          <label>
            <input
              type="radio"
              value="beginner"
              checked={shippingExperience === 'beginner'}
              onChange={() => setShippingExperience('beginner')}
            />
            Beginner
          </label>
          <label>
            <input
              type="radio"
              value="intermediate"
              checked={shippingExperience === 'intermediate'}
              onChange={() => setShippingExperience('intermediate')}
            />
            Intermediate
          </label>
          <label>
            <input
              type="radio"
              value="advanced"
              checked={shippingExperience === 'advanced'}
              onChange={() => setShippingExperience('advanced')}
            />
            Advanced
          </label>
          
          <button className='fbe-button' onClick={handleNext}>Next</button>
        </div>
      </div>
    </div>
  );
};

export default ForBetterExperience;
