import React, { useState, useEffect, useRef } from 'react';
import Chat from './Chat';
import io from 'socket.io-client';
import './Chat.css';

const ENDPOINT = 'http://localhost:5000';

const UserChat = () => {
  const [button, setButton] = useState(false);
  const socketRef = useRef();
  const username = sessionStorage.getItem('username');
  const isLoggedIn = sessionStorage.getItem('token') !== null;

  useEffect(() => {
    if (isLoggedIn) {
      socketRef.current = io(ENDPOINT);

      // Set the username
      socketRef.current.emit('setUsername', username);

      // Handle incoming messages
      socketRef.current.on('message', (message) => {
        console.log(`Message from ${message.sender}: ${message.message}`);
        // Display the message in the chat UI
      });

      return () => {
        socketRef.current.disconnect();
      };
    }
  }, [isLoggedIn, username]);

  const displayChat = () => {
    setButton((prevButton) => !prevButton);
  };

  return (
    <div>
      {isLoggedIn && <button className='chatWithMeButton' onClick={displayChat}></button>}
      {isLoggedIn && button && <Chat username={username} />}
    </div>
  );
};

export default UserChat;
