import React, { useEffect, useState, useRef } from 'react';
import { FiMapPin, FiCopy, FiShoppingCart, FiCheckCircle, FiTruck } from 'react-icons/fi'; // Importing icons from Feather Icons
import '../styles/ProcessMain.css';

const ShippingProcess = () => {
    const [loadingPercentage, setLoadingPercentage] = useState(0);
    const targetRef = useRef(null);

    const steps = [
        { id: 1, icon: <FiMapPin size={30} />, heading: 'Generate a P2P address', description: 'First register and login to your account, a UK address would be generated for you upon logging in' },
        { id: 2, icon: <FiCopy size={30} />, heading: 'Copy the Address', description: 'Copy the address generated upon login and use it as your shipping address when purchasing your items' },
        { id: 3, icon: <FiShoppingCart size={30} />, heading: 'Purchase your item', description: 'Go to your preferred online store and send your purchased items to the UK address provided to you' },
        { id: 4, icon: <FiCheckCircle size={30} />, heading: 'Confirmation & Processing', description: 'Once we receive your shipment, we would send you a confirmation email with your tracking information and process your item' },
        { id: 5, icon: <FiTruck size={30} />, heading: 'Track your item', description: 'Once your shipment is in transit, you can track your shipment using the tracking number in the information provided in the email' },
    ];

    const handleScroll = () => {
        const target = targetRef.current;
        if (target) {
            const targetRect = target.getBoundingClientRect();
            const windowHeight = window.innerHeight;
            const targetTop = targetRect.top;
            const targetBottom = targetRect.bottom;

            // Check if the div is in the viewport
            if (targetTop <= windowHeight) {
                const percentage = Math.min((windowHeight - targetTop) / windowHeight, 1) * 80;
                setLoadingPercentage(percentage);
            } else if (targetTop > windowHeight || targetBottom < 0) {
                // If the target div is out of the viewport, reverse the loading bar slowly
                setLoadingPercentage((prev) => Math.max(prev - 1, 0));
            }
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className='process-main'>
            <div className='s-process-heading'>
                <h2>Follow us through <span>our process</span></h2>
                <div className='underline'></div>
            </div>
            <div className="shipping-process" ref={targetRef}>
                {steps.map((step, index) => (
                    <div key={step.id} className={`process-step ${index % 2 === 0 ? 'left' : 'right'}`}>
                        <div className={`circle ${index % 2 === 0 ? 'circle-left' : 'circle-right'}`} style={index === 1 ? { backgroundColor: '#3f8893' } : {}}>
                            {step.icon}
                        </div>
                        <div className={`heading ${index % 2 === 0 ? 'heading-left' : 'heading-right'}`}>
                            <div className={`inner-info ${index % 2 === 0 ? 'inner-info-left' : 'inner-info-right'}`}>
                                <h3>{step.heading}</h3>
                                <p>{step.description}</p>
                            </div>
                        </div>
                        {index < steps.length - 1 && (
                            <div className={`line ${index % 2 === 0 ? 'line-right' : 'line-left'}`}>
                                <div className="scroll-indicator-lines" style={{ height: `${loadingPercentage}%` }}></div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ShippingProcess;
