import React, { useState, useEffect } from 'react';
import '../styles/SecondIntro.css';
import illustration1 from '../assets/illustrator/s-e-one.gif';
import illustration2 from '../assets/illustrator/s-e-two.png';
import illustration3 from '../assets/illustrator/s-e-three.png';
import illustration4 from '../assets/illustrator/s-e-four.png';
import illustration5 from '../assets/illustrator/s-e-five.png';

const SecondIntro = () => {
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveTab((prevTab) => (prevTab + 1) % 5);
        }, 10000);

        return () => clearInterval(interval);
    }, []);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    const tabContents = ["Generate P2P Address", "Choose a drop date", "Send your Package", "We process the invoice", "Receive your Package"];
    const illustrations = [illustration1, illustration2, illustration3, illustration4, illustration5];
    
    return (
        <div className="second-intro-container">
            <div className='main-split'>
            <div className='split-intro-two'>
            <h1>Say <span>hello</span> to the only online shipping portal you'll ever need</h1>
            <p>Ever tried getting stuff online but the store won’t ship to Africa? You do the shopping, we ship it! We understand your frustrations and we can help. Shop from your favorite online stores and we’ll deliver to your door</p>
            </div>

            <div className='tab-section'>
            <div className="tab-container">
                <div className="tabs">
                    {[...Array(5).keys()].map((index) => (
                        <div
                            key={index}
                            className={`tab ${activeTab === index ? 'active' : ''}`}
                            onClick={() => handleTabClick(index)}
                        ></div>
                    ))}
                </div>
            </div>
            <div className="content-container">
                <div className="content">
                    {tabContents.map((tabContent, index) => (
                        <div
                            key={index}
                            className={`tab-content ${activeTab === index ? 'active' : ''} ${index < 3 ? 'left' : 'right'}`}
                        >
                            <div className="tab-image">
                                <img loading='lazy' src={illustrations[index]} alt={`Illustration for Tab ${index + 1}`} />
                            </div>
                        </div>
                    ))}
                </div>
              </div>
            </div>
            </div>
        </div>
    );
};

export default SecondIntro;
