import React from 'react';
import { useLocation } from 'react-router-dom';
import jsPDF from 'jspdf';
import '../styles/DisplayTrackinginfo.css';
import Logo from '../components/Darklogo';
import Navbar from '../components/Navbar';

const DisplayTrackingInfo = () => {
    const location = useLocation();
    const trackingInfo = location.state?.trackingInfo;

    if (!trackingInfo) {
        return <div className="container">No tracking information found.</div>;
    }

    const handleDownload = () => {
        const doc = new jsPDF();

        // Set document background color to black
        doc.setFillColor(27, 38, 52); // RGB for #1b2634
        doc.rect(0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height, 'F');

        // Add header
        doc.setTextColor(255, 255, 255); // White color for text
        doc.setFontSize(16);
        doc.text('Tracking Information', doc.internal.pageSize.width / 2, 20, { align: 'center', baseline: 'middle' });

        // Add each property with styling
        let yPosition = 40; // Start position for the first line of information
        const lineSpacing = 20;
        const pageWidth = doc.internal.pageSize.width;

        doc.setFontSize(12);
        doc.setTextColor(255, 255, 255); // White color for text
        // Green color for label
        doc.text('Tracking Number:', pageWidth / 2, yPosition, { baseline: 'middle' });
        doc.setTextColor(0, 0, 255); // Blue color for value
        doc.text(trackingInfo.trackingNumber, pageWidth / 2, yPosition + 10, { baseline: 'middle' });

        yPosition += lineSpacing;
        doc.setTextColor(255, 255, 255); // White color for text
        doc.text('Email:', pageWidth / 2, yPosition, { baseline: 'middle' });
        doc.setTextColor(0, 0, 255); // Blue color for value
        doc.text(trackingInfo.email, pageWidth / 2, yPosition + 10, { baseline: 'middle' });

        yPosition += lineSpacing;
        doc.setTextColor(255, 255, 255); // White color for text
        doc.text('Product Name:', pageWidth / 2, yPosition, { baseline: 'middle' });
        doc.setTextColor(0, 0, 255); // Blue color for value
        doc.text(trackingInfo.productName, pageWidth / 2, yPosition + 10, { baseline: 'middle' });

        yPosition += lineSpacing;
        doc.setTextColor(255, 255, 255); // White color for text
        doc.text('Estimated Delivery Time:', pageWidth / 2, yPosition, { baseline: 'middle' });
        doc.setTextColor(0, 0, 255); // Blue color for value
        doc.text(trackingInfo.estimatedTimeOfArrival, pageWidth / 2, yPosition + 10, { baseline: 'middle' });

        yPosition += lineSpacing;
        doc.setTextColor(255, 255, 255); // White color for text
        doc.text('Order Status:', { baseline: 'middle' });
        doc.setTextColor(0, 0, 255); // Blue color for value
        doc.text(trackingInfo.orderStatus, pageWidth / 2, yPosition + 10, { baseline: 'middle' });

        yPosition += lineSpacing;
        doc.setTextColor(255, 255, 255); // White color for text
        doc.text('Time', pageWidth / 2, yPosition);
        doc.setTextColor(0, 0, 255); // Blue color for value
        doc.text(new Date(trackingInfo.timestamp).toLocaleString(), pageWidth / 2, yPosition + 10, { baseline: 'middle' });

        doc.save(`tracking-info${trackingInfo.trackingNumber}.pdf`);
    };

    return (
        <div className="container">
            <Logo />
            <div className="main-container">
                <h1 className="header">Tracking Information</h1>
                <p className="info"><span>Tracking Number:</span> {trackingInfo.trackingNumber}</p>
                <p className="info"><span>Email:</span> {trackingInfo.email}</p>
                <p className="info"><span>Product Name:</span> {trackingInfo.productName}</p>
                <p className="info"><span>Estimated Delivery Time:</span> {trackingInfo.estimatedTimeOfArrival}</p>
                <p className="info"><span>Order Status:</span> {trackingInfo.orderStatus}</p>
                <p>{new Date(trackingInfo.timestamp).toLocaleString()}</p>
                <button onClick={handleDownload}>Download</button>
            </div>
            <Navbar />
        </div>
    );
};

export default DisplayTrackingInfo;
