import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ProfilePage.css';
import Calculator from './Calculator';
import Dashboard from './Dashbstats';
import Usernavbar from './Usernavbar';
import ghflag from '../assets/images/gh-flag-img.jpg';
import njflag from '../assets/images/naija-flag-img.jpg';


const ProfilePage = () => {
  const [activeTab, setActiveTab] = useState('profile');
  const [userData, setUserData] = useState({});
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    phonenumber: '',
    countrycode: '',
    username: '',
    sex: '',
    dateofbirth: '',
    email: ''
  });
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [showConfirmation, setShowConfirmation] = useState(false); // State for confirmation dialog

  useEffect(() => {
    const token = localStorage.getItem('token');
  
    if (token) {
      axios
        .get('http://localhost:5000/api/user/profile', {
          headers: {
            'Authorization': `Bearer ${token}` 
          }
        })
        .then((response) => { 
          const userData = response.data;
          console.log('User data:', userData);
          setUserData(userData);
          setFormData({
            firstname: userData.firstname,
            lastname: userData.lastname,
            phonenumber: userData.phonenumber,
            countrycode: userData.countrycode,
            username: userData.username,
            sex: userData.sex,
            dateofbirth: userData.dateofbirth,
            email: userData.email
          });
        })
        .catch((error) => {
          console.error('Error fetching user data:', error);
        });
    }

  }, []);
   

  const handleFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePasswordChange = (e) => {
    setPasswordData({ ...passwordData, [e.target.name]: e.target.value });
  };

  const handleSaveProfile = () => {
    const token = localStorage.getItem('token');
  
    const updatedProfileData = {
      ...formData,
      currentUsername: userData.username, // Send the current username
    };
  
    console.log('Form data:', updatedProfileData);
    axios.put('http://localhost:5000/api/user/updateProfile', updatedProfileData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => {
        alert('Profile updated successfully');
      })
      .catch(error => {
        console.error('Error updating profile:', error);
      });
  };
  
  

  const handleChangePassword = () => {
    const token = localStorage.getItem('token'); 
    axios.post('http://localhost:5000/api/user/changePassword', passwordData, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
      .then(response => {
        alert('Password changed successfully');
      })
      .catch(error => {
        console.error('Error changing password:', error);
      });
  };

  const handleDeleteAccount = () => {
    setShowConfirmation(true); // Show confirmation dialog
  };

  const confirmDeleteAccount = () => {
    const token = localStorage.getItem('token');

    if (token) {
      axios
        .delete('https://p2pbackendserver-six.vercel.app/api/user/deleteAccount', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((response) => {
          alert('Account deleted successfully');
          localStorage.removeItem('token');
          window.location.href = '/';
        })
        .catch((error) => {
          console.error('Error deleting account:', error);
        });
    }
  };

  return (
    <div className="profile-page">
       <Usernavbar />

      {/* Tab Navigation */}
      <div className="user-tab-section">
        <button className={activeTab === 'profile' ? 'active' : ''} onClick={() => setActiveTab('profile')}>Profile</button>
        <button className={activeTab === 'Calculator' ? 'active' : ''} onClick={() => setActiveTab('Calculator')}>Calculator</button>
        <button className={activeTab === 'Dashboard' ? 'active' : ''} onClick={() => setActiveTab('Dashboard')}>Dashboard</button>
      </div>

      <div className="prof-tab-content">
        {activeTab === 'profile' && (
          <div className="prof-tab-section">
            <h2>Profile Information</h2>
            <div className="profile-form">
              <div className='profile-form-inner'>
              <input type="text" placeholder={userData.firstname} disabled onChange={() => { return false }} />
              <input type="text" placeholder={userData.lastname} disabled onChange={() => { return false }} />
              </div>

              <div className='profile-form-inner'>
              <input type="text" style={{backgroundColor: "white"}} name="phonenumber" value={formData.phonenumber} onChange={handleFormChange} placeholder="Phone Number" />
              <div className="user-flag-container">
              {userData.countrycode === '+233' ? (
                <>
                <img loading="lazy" className="flag" src={ghflag} alt="gh Profile Icon" />
                <p className="flag-name">Ghana</p>
                </>
              ) : (
                <>
                <img loading="lazy" className="flag" src={njflag} alt="nj Profile Icon" />
                <p className="flag-name">Nigeria</p>
                </>
              )}
              </div>

              </div>

              <div className='profile-form-inner'>
              <input type="text" style={{backgroundColor: "white"}} name="username" value={formData.username} onChange={handleFormChange} placeholder="Username" />
              <select name="sex" value={formData.sex} onChange={handleFormChange}>
                <option value="">Select Sex</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
              </div>

              <div className='profile-form-inner'>
              <input style={{backgroundColor: "white"}} type="date" name="dateofbirth" value={formData.dateofbirth} onChange={handleFormChange} placeholder="Date of Birth" />
              <input style={{backgroundColor: "white"}} type="email" name="email" value={formData.email} onChange={handleFormChange} placeholder="Email" />
              </div>
              <button id='user-btn' onClick={handleSaveProfile}>Save Changes</button>
            </div>


            <hr />

            <h2>Login and Security</h2>
            <div className='profile-form-inner'>
            <input type="password" name="currentPassword" value={passwordData.currentPassword} onChange={handlePasswordChange} placeholder="Current Password" />
            <input type="password" name="newPassword" value={passwordData.newPassword} onChange={handlePasswordChange} placeholder="New Password" />
            <input type="password" name="confirmPassword" value={passwordData.confirmPassword} onChange={handlePasswordChange} placeholder="Confirm Password" />
            </div>
            <button id='user-btn' onClick={handleChangePassword}>Change Password</button>

            <hr />

            <h2>Help and Support</h2>
            <div className='support-inner'>
            <p style={{color: 'black'}}>Let's get the help you need.</p>
            <a href="/support">Visit Support Page</a>
            </div>

            <hr />
            <p className='delete-account' onClick={handleDeleteAccount}>Delete Account</p>
          </div>
        )}
        {activeTab === 'Calculator' && <Calculator />}
        {activeTab === 'Dashboard' && <Dashboard />}
      </div>

      {showConfirmation && (
        <div className="confirmation-modal">
          <div className="confirmation-content">
            <p>Are you sure you want to delete your account?</p>
            <div className='inner'>
            <button onClick={confirmDeleteAccount}>Yes</button>
            <button onClick={() => setShowConfirmation(false)}>No</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfilePage;
