import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/images/logowhitetext.png';
import '../styles/Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {

    const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="top-section">
        <div className="left-column">
        <Link to="/">
          <img loading='lazy' src={Logo} alt="Footer-Logo" className="logo" />
        </Link>
          <div className="social-icons">
          <a href="#" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebook} className="icon" />
        </a>
        <a href="#" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faTwitter} className="icon" />
        </a>
        <a href="https://www.instagram.com/p2plogisticsgh" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} className="icon" />
        </a>
          </div>
      </div>



        <div className="middle-column">
          <div className='middle-kids'>
              <Link to="/faq">Faq</Link>
              <Link to="/blog">Blog</Link>
          </div>
          <div className='middle-kids'>
             <Link to="/legal">Legal</Link>
             <Link to="/contact">Help</Link>
          </div>
        </div>


        <div className="right-column">
        <div className="nav-email">
          <div className="nav-info-ghuk">
          <img loading='lazy' width="18" height="18" src="https://img.icons8.com/retro/32/000000/new-post.png" alt="new-post"/>
              <p>Email Address</p>
            </div>
              <Link>p2plogistics@gmail.com</Link>
        </div>            

        <div className="nav-phone">
        <div className="nav-info-ghuk">
        <img loading='lazy' width="16" height="16" src="https://img.icons8.com/office/16/phone-disconnected.png" alt="phone-disconnected"/>
        <p>Ghana</p>
        </div>
        <a href="tel:+233 50 389 0000" className="nav-phone">+233 50 389 0000</a>
        </div> 

        <div className="nav-phone">
        <div className="nav-info-ghuk">
        <img loading='lazy' width="16" height="16" src="https://img.icons8.com/office/16/phone-disconnected.png" alt="phone-disconnected"/>
        <p>UK</p>
        </div>
        <a href="tel:+233245353583" className="nav-phone">+233 50 389 0000</a>
        </div>
    
        </div>
      </div>
      
      <div className="bottom-section">
      <p className="copywrite">© {currentYear} P2P Logistics - All rights reserved.</p> 
      </div>
    </footer>
  );
}

export default Footer;
