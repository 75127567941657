import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Orders.css';
import Usernavbar from './Usernavbar';

function Orders() {
  const [userId, setUserId] = useState('');
  const [orders, setOrders] = useState([]); // All fetched orders
  const [filteredOrders, setFilteredOrders] = useState([]); // Filtered orders to display
  const [searchQuery, setSearchQuery] = useState(''); // State for search input
  const [statusFilter, setStatusFilter] = useState(''); // State for status dropdown

  useEffect(() => {
    const storedUserId = localStorage.getItem('userId');
    if (storedUserId) {
      setUserId(storedUserId);
      fetchOrders(storedUserId);
    }
  }, []);

  const fetchOrders = async (userId) => {
    try {
      const response = await axios.post('https://p2pbackendserver-six.vercel.app/api/user/user-orders', { userId });
      setOrders(response.data);
      setFilteredOrders(response.data); // Initialize filtered orders
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };


  // Function to handle search input changes
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Function to handle search button click
  const handleSearch = () => {
    filterOrders(searchQuery, statusFilter);
  };

  // Function to handle status dropdown change
  const handleStatusChange = (e) => {
    setStatusFilter(e.target.value);
    filterOrders(searchQuery, e.target.value);
  };

  // Function to filter orders based on search query and status
  const filterOrders = (search, status) => {
    let filtered = orders;

    if (search) {
      filtered = filtered.filter(order =>
        order.trackingNumber.toLowerCase().includes(search.toLowerCase()) ||
        order.email.toLowerCase().includes(search.toLowerCase()) ||
        order.productName.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (status) {
      filtered = filtered.filter(order => order.orderStatus === status);
    }

    setFilteredOrders(filtered);
  };

  return (
    <>
    <Usernavbar />
    <div className='orders-container'>
      <div className="filters-section">
        {/* Search Input */}
        <div className="search-div">
          <input
            type="text"
            placeholder="Search orders..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="search-input"
          />
          <button onClick={handleSearch} className="search-button">
            🔍 {/* Magnifying glass icon */}
          </button>
        </div>

        {/* Status Dropdown */}
        <div className="status-dropdown">
          <select value={statusFilter} onChange={handleStatusChange}>
            <option value="">Search Status</option>
            <option value="Processing">Processing</option>
            <option value="Shipped">Shipped</option>
            <option value="Delivered">Delivered</option>
            <option value="Cancelled">Cancelled</option>
          </select>
        </div>
      </div>

      {/* Orders Display */}
      <div className='orders-info'>
        {filteredOrders.length > 0 ? (
          filteredOrders.map(order => (
            <div key={order.id} className='order-card'>
              <p className="info"><span>Tracking Number:</span> {order.trackingNumber}</p>
              <p className="info"><span>Email:</span> {order.email}</p>
              <p className="info"><span>Product Name:</span> {order.productName}</p>
              <p className="info"><span>Estimated Delivery Time:</span> {order.estimatedTimeOfArrival}</p>
              <p className="info"><span>Order Status:</span> {order.orderStatus}</p>
              <p>{new Date(order.timestamp).toLocaleString()}</p>
            </div>
          ))
        ) : (
          <div className='no-orders'>No orders at the moment.</div>
        )}
      </div>
    </div>
    </>
  );
}

export default Orders;
