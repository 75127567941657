import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Address.css';

const Shipment = () => {
  const [addresses, setAddress] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  
  useEffect(() => {

    const getAddress = async () => {
      try {
        const response = await axios.get('https://p2pbackendserver-six.vercel.app/getaddress');
        setAddress(response.data);
      } catch (error) {
        console.error('Error retrieving address:', error);
      }
    };

    getAddress();
  }, []);



  return (
    <div className='address-box'>
      {addresses.length > 0 ? (
        <div>Shipment Info</div>
    ) : (
      <div className='no-addresses'>Undergoing maintenance</div>
    )}
    </div>
  );
};

export default Shipment;
