import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend } from 'chart.js';
import '../styles/Stats.css';

// Register the necessary elements and components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend);

const Stats = () => {
  const [stats, setStats] = useState({
    daily: { trackingCount: 0, scheduleCount: 0 },
    weekly: { trackingCount: 0, scheduleCount: 0 },
    monthly: { trackingCount: 0, scheduleCount: 0 },
    yearly: { trackingCount: 0, scheduleCount: 0 },
    loading: true,
    error: null,
  });

  const [cumulativeReports, setCumulativeReports] = useState([]);
  const [showCumulativeReport, setShowCumulativeReport] = useState(false);

  useEffect(() => {
    fetchStatsData();
  }, []);

  const fetchStatsData = async () => {
    try {
      const response = await axios.get('https://p2pbackendserver-six.vercel.app/stats');
      setStats({
        daily: response.data.daily,
        weekly: response.data.weekly,
        monthly: response.data.monthly,
        yearly: response.data.yearly,
        loading: false,
        error: null,
      });
    } catch (error) {
      setStats({
        daily: { trackingCount: 0, scheduleCount: 0 },
        weekly: { trackingCount: 0, scheduleCount: 0 },
        monthly: { trackingCount: 0, scheduleCount: 0 },
        yearly: { trackingCount: 0, scheduleCount: 0 },
        loading: false,
        error: 'Error fetching stats',
      });
      console.error('Error fetching stats:', error);
      toast.error('Failed to fetch stats');
    }
  };

  const fetchCumulativeReport = async () => {
    try {
      const response = await axios.get('https://p2pbackendserver-six.vercel.app/cumulative-report');
      setCumulativeReports(response.data);
    } catch (error) {
      console.error('Error fetching cumulative report:', error);
      toast.error('Failed to fetch cumulative report');
    }
  };

  const toggleCumulativeReport = () => {
    setShowCumulativeReport(!showCumulativeReport);
    if (!showCumulativeReport) {
      fetchCumulativeReport();
    }
  };

  if (stats.loading) {
    return <div className='intro-box'>
      <div className="three-body">
        <div className="three-body__dot"></div>
        <div className="three-body__dot"></div>
        <div className="three-body__dot"></div>
      </div>
    </div>;
  }

  if (stats.error) {
    return <p style={{color: "black"}}>Error: {stats.error}</p>;
  }

  // Chart data
  const chartData = {
    labels: ['Daily', 'Weekly', 'Monthly', 'Yearly'],
    datasets: [
      {
        label: 'Orders',
        data: [stats.daily.trackingCount, stats.weekly.trackingCount, stats.monthly.trackingCount, stats.yearly.trackingCount],
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
      {
        label: 'Drops',
        data: [stats.daily.scheduleCount, stats.weekly.scheduleCount, stats.monthly.scheduleCount, stats.yearly.scheduleCount],
        backgroundColor: 'rgba(153, 102, 255, 0.6)',
      },
    ],
  };



  const lineChartData = {
    labels: ['Daily', 'Weekly', 'Monthly', 'Yearly'],
    datasets: [
      {
        label: 'Orders Over Time',
        data: [stats.daily.trackingCount, stats.weekly.trackingCount, stats.monthly.trackingCount, stats.yearly.trackingCount],
        fill: false,
        borderColor: 'rgba(75, 192, 192, 1)',
        tension: 0.1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Tracking & Schedule Statistics',
      },
    },
  };

  return (
    <div className="stats-container">
      <div className="stats-item">
        <div className='dashicon'></div>
        <div className='stats-info'>
          <p>Daily: {stats.daily.trackingCount} Order{stats.daily.trackingCount !== 1 ? 's' : ''}</p>
          <p>{stats.daily.scheduleCount} Drop{stats.daily.scheduleCount !== 1 ? 's' : ''}</p>
        </div>
      </div>

      <div className="stats-item">
        <div className='dashicon'></div>
        <div className='stats-info'>
          <p>Weekly: {stats.weekly.trackingCount} Order{stats.weekly.trackingCount !== 1 ? 's' : ''}</p>
          <p>{stats.weekly.scheduleCount} Drop{stats.weekly.scheduleCount !== 1 ? 's' : ''}</p>
        </div>
      </div>

      <div className="stats-item">
        <div className='dashicon'></div>
        <div className='stats-info'>
          <p>Monthly: {stats.monthly.trackingCount} Order{stats.monthly.trackingCount !== 1 ? 's' : ''}</p>
          <p>{stats.monthly.scheduleCount} Drop{stats.monthly.scheduleCount !== 1 ? 's' : ''}</p>
        </div>
      </div>

      <div className="stats-item">
        <div className='dashicon'></div>
        <div className='stats-info'>
          <p>Yearly: {stats.yearly.trackingCount} Order{stats.yearly.trackingCount !== 1 ? 's' : ''}</p>
          <p>{stats.yearly.scheduleCount} Drop{stats.yearly.scheduleCount !== 1 ? 's' : ''}</p>
        </div>
      </div>

      <div className='cumulative-section'>
        <button className="cumulative-report-button" onClick={toggleCumulativeReport}>
          {showCumulativeReport ? 'Hide Cumulative Report' : 'Get Cumulative Report'}
        </button>
        {showCumulativeReport && (
           <div className='cumulative-inner'>
           <div className="report-scroll-container">
             <table className="cumulative-report-table">
               <thead>
                 <tr>
                   {cumulativeReports.map((report, index) => (
                     <th key={index}>{report.period} Report</th>
                   ))}
                 </tr>
               </thead>
               <tbody>
                 <tr>
                   {cumulativeReports.map((report, index) => (
                     <td key={index}>
                       {Object.entries(report.data).map(([date, count]) => (
                         <div key={date} className='stt'>
                           <p>{date}:</p>
                           <p><strong>{count}</strong> order{count !== 1 ? 's' : ''} completed</p>
                         </div>
                       ))}
                     </td>
                   ))}
                 </tr>
               </tbody>
             </table>
           </div>
         </div>
        )}
          <div className='chart-container'>
            <div className='chart-item'>
              <Bar data={chartData} options={chartOptions} />
            </div>
            <div className='chart-item'>
              <Line data={lineChartData} options={chartOptions} />
            </div>
          </div>
      </div>
    </div>
  );
};

export default Stats;
