import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import './Calculator.css';


function ShippingCalculator() {
  const [originCountry, setOriginCountry] = useState('');
  const [destinationCountry, setDestinationCountry] = useState('');
  const [shipmentWeightKg, setShipmentWeightKg] = useState('');
  const [shipmentWeightLbs, setShipmentWeightLbs] = useState('');
  const [totalCost, setTotalCost] = useState(0);

  useEffect(() => {
    if (shipmentWeightKg === '') {
      setShipmentWeightLbs('');
      setTotalCost(0);
      return;
    }

    const weightKg = parseFloat(shipmentWeightKg);
    if (isNaN(weightKg) || weightKg <= 0) {
      setShipmentWeightLbs('');
      setTotalCost(0);
      return;
    }

    const lbsEquivalent = weightKg * 2.20462;
    setShipmentWeightLbs(lbsEquivalent.toFixed(2));

    if (weightKg > 20) {
      toast.error('Weight exceeds the maximum allowed limit.');
      setTotalCost(0);
      return;
    }

    let shippingRate = 0;
    if (weightKg <= 2) shippingRate = 30;
    else if (weightKg <= 4) shippingRate = 55;
    else if (weightKg <= 6) shippingRate = 75;
    else if (weightKg <= 8) shippingRate = 90;
    else if (weightKg <= 11) shippingRate = 110;
    else if (weightKg <= 14) shippingRate = 130;
    else if (weightKg <= 16) shippingRate = 150;
    else if (weightKg <= 18) shippingRate = 170;
    else shippingRate = 200;

    setTotalCost(shippingRate);
  }, [shipmentWeightKg]);

  return (
    <div className="shipping-calculator-wrapper">

      <div className='skw-inner'>
      <section className="shipping-calculator">
        <h2>Shipping Price Calculator</h2>
        <p>Enter the details below to calculate your shipping cost.</p>
        <div className="input-group">
          <div className="input-row">
            <label htmlFor="originCountry">Origin Country</label>
            <select
              id="originCountry"
              value={originCountry}
              onChange={(e) => setOriginCountry(e.target.value)}
              className="location-select"
            >
              <option value="">Select Origin</option>
              <option value="UK">UK</option>
            </select>

            <label htmlFor="destinationCountry">Destination Country</label>
            <select
              id="destinationCountry"
              value={destinationCountry}
              onChange={(e) => setDestinationCountry(e.target.value)}
              className="location-select"
            >
              <option value="">Select Destination</option>
              <option value="Ghana">Ghana</option>
            </select>
          </div>
          <div className="input-row">
            <label htmlFor="shipmentWeightKg">Weight (kg)</label>
            <input
              id="shipmentWeightKg"
              type="number"
              placeholder="kg"
              value={shipmentWeightKg}
              onChange={(e) => setShipmentWeightKg(e.target.value)}
            />

            <label htmlFor="shipmentWeightLbs">Weight (lbs)</label>
            <input
              id="shipmentWeightLbs"
              type="number"
              placeholder="lbs"
              value={shipmentWeightLbs}
              disabled
            />
          </div>
          <div className="input-row">
            <label htmlFor="totalCost">Total Cost</label>
            <input
              id="totalCost"
              type="number"
              placeholder="Total Cost"
              value={totalCost}
              disabled
            />
          </div>
        </div>
      </section>
      </div>
    </div>
  );
}

export default ShippingCalculator;
